var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.news.items.length === 0
        ? _c("NodataTemplate3", {
            staticClass: "mt-10",
            attrs: { text: _vm.$t("global.noData") }
          })
        : _vm._l(_vm.news.items, function(item, index) {
            return _c(
              "v-col",
              { key: item._id, staticClass: "py-1", attrs: { cols: "12" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-decoration-none",
                    on: {
                      click: function($event) {
                        return _vm.goMemberMessageItem({
                          id: item._id,
                          i: index + 1
                        })
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "news rounded title--text subtitle-2 pa-4",
                        class: [item.read === "N" ? "inputBg" : "card1"]
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "news__header d-flex align-center" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "news__header__title",
                                class: [
                                  item.read === "N"
                                    ? _vm.theme === "light"
                                      ? "primary--text"
                                      : "secondary--text"
                                    : "hint--text"
                                ]
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.title && item.title.length > 12
                                        ? item.title.slice(0, 10) + "..."
                                        : item.title
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c("v-spacer"),
                            _c(
                              "span",
                              {
                                staticClass: "news__header__time caption",
                                class: [
                                  item.read === "N"
                                    ? "icon--text"
                                    : "hint--text"
                                ]
                              },
                              [_vm._v(" " + _vm._s(item.start_datetime) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }