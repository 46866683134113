var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-56 h-100dvh over-flow-y-scroll" },
    [
      _c(
        "v-container",
        [
          (_vm.$route.query.t === "mn"
          ? _vm.memberNewsItem
          : _vm.newsItem)
            ? _c(
                "v-row",
                { staticClass: "px-1" },
                [
                  _c("v-col", { staticClass: "news", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "news__title subtitle-1 font-weight-medium"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$route.query.t === "mn"
                                ? _vm.memberNewsItem.title
                                : _vm.newsItem.title
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "news__date text-right caption hint--text"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$route.query.t === "mn"
                                ? _vm.memberNewsItem.start_datetime
                                : _vm.newsItem.start_datetime
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("v-col", { staticClass: "pt-0", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card1 pa-4 rounded caption font-weight-regular fix-img-size"
                      },
                      [
                        _vm.$route.query.t === "mn"
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.memberNewsItem.content)
                              }
                            })
                          : _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.newsItem.content)
                              }
                            })
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }